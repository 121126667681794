import * as Yup from "yup"
import { InventoryIntervalOptions } from "./CreateOrEditAssetForm/InventoryIntervalOptions"

export const InventoryRequirementsValidationSchema = Yup.object().shape({
  intervalInSeconds: Yup.string().oneOf(Object.values(InventoryIntervalOptions).map((n) => n.toString())),
  photoRequired: Yup.boolean(),
})

export const InspectionRequirementsValidationSchema = Yup.object()
  .shape({
    intervalInSeconds: Yup.string()
      .oneOf(["", "none", ...Object.values(InventoryIntervalOptions).map((n) => n.toString())])
      .nullable(),
    startDate: Yup.string().nullable(),
    assetReportTemplateId: Yup.string(),
  })
  .nullable()
