import { CheckboxProps, FormControlLabel, FormGroup, Checkbox as MuiCheckbox } from "@mui/material"
import { useField } from "formik"

export const Checkbox = (props: CheckboxProps & { name: string }) => {
  const [{ value = false }, {}, { setValue }] = useField(props.name)

  return (
    <MuiCheckbox
      {...props}
      size="small"
      checked={value}
      onChange={(e, checked) => {
        setValue(checked)
        props.onChange?.(e, checked)
      }}
    />
  )
}

export const LabeledCheckbox = (props: CheckboxProps & { name: string; label: string }) => {
  return (
    <FormGroup>
      <FormControlLabel className="select-none" control={<Checkbox {...props} />} label={props.label} />
    </FormGroup>
  )
}
