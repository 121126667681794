import { TextFieldProps } from "@mui/material"
import { TextField } from "./TextField"

const maxYear = new Date().getFullYear() + 2

export const YearField = (props: TextFieldProps & { name: string }) => {
  return (
    <TextField
      {...props}
      fullWidth
      label={props.label ?? "Year"}
      name={props.name ?? "year"}
      type="number"
      inputProps={{
        min: 1900,
        max: maxYear,
      }}
      onChange={(e) => {
        e.currentTarget.value = e.currentTarget.value.slice(0, 4)
      }}
    />
  )
}
