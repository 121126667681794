import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { useField } from "formik"
import { FC, HTMLAttributes } from "react"
import { useQuery } from "urql"
import { graphql } from "../../graphql/generated/gql"

const AssetBillingClassificationSelectDocument = graphql(`
  query AssetBillingClassificationSelect {
    assetBillingClassifications {
      id
      name
    }
  }
`)

type Props = {
  disabled?: boolean
  name: string
}

interface HTMLAttributeType extends HTMLAttributes<HTMLLIElement> {
  key?: React.Key
}

export const AssetBillingClassificationSelect: FC<Props> = ({ disabled, name }) => {
  const [{ data, fetching }] = useQuery({ query: AssetBillingClassificationSelectDocument })
  const [field, { touched, error }, { setValue }] = useField(name)

  const errorShouldBeDisplayed = touched && !!error && !disabled

  const options = data?.assetBillingClassifications || []

  const selectedOption = options.find((option) => option.id === field.value) || null

  return (
    <Autocomplete
      className="grow"
      disabled={fetching || !!error}
      filterOptions={(filters, params) =>
        filters.filter((option) => option.name.toLowerCase().includes(params.inputValue.toLowerCase()))
      }
      onChange={(_e, value) => setValue(value ? value.id : null)}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          value={selectedOption}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label="Billing Classification"
          placeholder="Billing Classification"
          error={errorShouldBeDisplayed}
          helperText={error}
        />
      )}
      renderOption={({ key: _, ...props }: HTMLAttributeType, option) => (
        <li key={option.id} {...props}>
          {option.name}
        </li>
      )}
      value={selectedOption}
    />
  )
}
