import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { ArrayHelpers, FieldArray, useField } from "formik"
import { FC, useContext, useEffect, useState } from "react"
import { BiTrash } from "react-icons/bi"
import { useQuery } from "urql"
import { AssetInspectionTemplateAssignments } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { pluralize } from "../../../../helpers/strings/pluralize"
import { PickPlus } from "../../../../types/helpers"
import { DropDownMUI, DropDownMUIItem } from "../../../DropDownMUI"
import { DatePicker } from "../../../Formik/DatePicker"
import { QuickMenu } from "../../../QuickMenu"
import { QuickMenuDotsHorizontal } from "../../../QuickMenu/QuickMenuDotsHorizontal"
import { InventoryIntervalOptions } from "./InventoryIntervalOptions"
import { DevelopmentFeatureFlagContext } from "../../../../providers/DevelopmentFeatureFlagProvider"

const AssetInspectionFormQueryDocument = graphql(`
  query AssetInspectionForm($filterByActiveTemplates: Boolean!) {
    reusableAssetReportTemplates(filterByActiveTemplates: $filterByActiveTemplates) {
      id
      name
      universal
    }
  }
`)

export const AssetInspectionForm: FC = () => {
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)
  const [{ data }] = useQuery({
    query: AssetInspectionFormQueryDocument,
    variables: {
      filterByActiveTemplates: flagIsEnabled("Divisions"),
    },
  })

  const [options, setOptions] = useState<DropDownMUIItem[][] | undefined>(undefined)
  const [field] =
    useField<
      PickPlus<AssetInspectionTemplateAssignments, "assetReportTemplateId" | "intervalInSeconds" | "startDate">[]
    >("inspectionRequirements")

  useEffect(() => {
    if (data) {
      setOptions(() => {
        const opts = []
        const orgTemplates = data?.reusableAssetReportTemplates
          .filter((template) => !template.universal)
          .map((template) => ({ value: template.id, label: template.name }))
        const universalTemplates = data.reusableAssetReportTemplates
          .filter((template) => template.universal)
          .map((template) => ({ value: template.id, label: template.name }))

        if (orgTemplates.length) opts.push(orgTemplates)
        if (universalTemplates.length) opts.push(universalTemplates)
        if (!opts.length) opts.push([{ value: "", label: "No Templates", disabled: true }])

        return opts
      })
    }
  }, [data])

  const frequencyOptions: DropDownMUIItem[] = [
    { value: "none", label: "None", color: "text-gray-400" },
    ...Object.keys(InventoryIntervalOptions).map((key) => ({
      value: InventoryIntervalOptions[key].toString(),
      label: key,
    })),
  ]

  const value = field.value || []

  // Conditionally rendering to asynchronously set the default value for assetInspectionTemplateId
  // If there is a better way, we can change it
  if (!options) return null

  return (
    <Box
      sx={{
        border: "1px solid #E5E5E5",
        borderRadius: "8px",
        marginBottom: "24px",
      }}
    >
      <FieldArray
        name="inspectionRequirements"
        render={(arrayHelpers: ArrayHelpers) => {
          return (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box padding={1}>Inspection Template</Box>
                    </TableCell>
                    <TableCell>
                      <Box padding={1}>Frequency</Box>
                    </TableCell>
                    <TableCell>
                      <Box padding={1}>Start date</Box>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value?.map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <DropDownMUI
                          items={options.flat().map((option) => {
                            return {
                              value: option.value,
                              label: option.label,
                              // disable if the value is already selected on another row
                              disabled: value.some((v) => v.assetReportTemplateId === option.value),
                            }
                          })}
                          fieldName={`inspectionRequirements.${index}.assetReportTemplateId`}
                          label=""
                        />
                      </TableCell>
                      <TableCell>
                        <DropDownMUI
                          items={frequencyOptions}
                          fieldName={`inspectionRequirements.${index}.intervalInSeconds`}
                          label=""
                        />
                      </TableCell>
                      <TableCell>
                        <DatePicker name={`inspectionRequirements.${index}.startDate`} className="w-full" />
                      </TableCell>
                      <TableCell size="small">
                        <QuickMenu
                          items={[
                            [
                              {
                                value: "Delete",
                                onClick: () => arrayHelpers.remove(index),
                                Icon: BiTrash,
                                color: "red",
                              },
                            ],
                          ]}
                          buttonShape="round"
                        >
                          <QuickMenuDotsHorizontal />
                        </QuickMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box paddingY={2} paddingX={1} display="flex" alignItems="center" justifyContent="space-between">
                <Button
                  onClick={() => arrayHelpers.push({ assetReportTemplateId: "", intervalInSeconds: "", startDate: "" })}
                  size="large"
                  variant="text"
                  disabled={value.length >= options.flat().length}
                >
                  + Add inspection checklist
                </Button>
                <Typography>
                  {value.length} {pluralize("row", value.length)}
                </Typography>
              </Box>
            </>
          )
        }}
      />
    </Box>
  )
}
