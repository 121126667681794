import { useField } from "formik"
import { FC } from "react"
import { DropDownMUI, DropDownMUIItem } from "../../../DropDownMUI"
import { LabeledCheckbox } from "../../../Formik/Checkbox"
import { Switch } from "../../../Formik/Switch"
import { InventoryIntervalOptions } from "./InventoryIntervalOptions"

export const handleInventoryRequirementsSubmission = (values: {
  photoRequired?: boolean
  intervalInSeconds?: string
}) => ({ ...values, intervalInSeconds: values.intervalInSeconds ? +values.intervalInSeconds : undefined })

export const InventoryRequirementsForm: FC = () => {
  const options: DropDownMUIItem[] = [
    { value: "", label: "None", color: "text-gray-400" },
    ...Object.keys(InventoryIntervalOptions).map((key) => ({
      value: InventoryIntervalOptions[key].toString(),
      label: key,
    })),
  ]

  const [{ value: isEnabled }] = useField<boolean>("inventoryIsRequired")

  return (
    <div>
      <div className="mb-6">
        <Switch label="Activate Inventory Checklist" name="inventoryIsRequired" />
      </div>
      {isEnabled && (
        <div className="p-5 border rounded flex-col gap-y-4 flex">
          <DropDownMUI
            items={options}
            fieldName="inventoryRequirements.intervalInSeconds"
            label="Choose inventory frequency"
          />

          <LabeledCheckbox label="Photo Required" required={false} name="inventoryRequirements.photoRequired" />
        </div>
      )}
    </div>
  )
}
